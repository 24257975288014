<template>
	<div class="main-wrap">
		<div class="block">
			<div class="title">
				<span class="label">工单：</span>
				<span class="value">2357274</span>
			</div>
		</div>
		<div class="block">
			<div class="bar-list">
				<div class="bar-item">
					<div class="icon"></div>
					<p class="label">开始</p>
					<p class="time">2023-02-22</p>
					<p class="time">12:10:32</p>
				</div>
				<div class="bar-item">
					<div class="icon"></div>
					<p class="label">身份核查</p>
				</div>
				<div class="bar-item">
					<div class="icon"></div>
					<p class="label">结束</p>
					<p class="time">2023-02-22</p>
					<p class="time">13:10:32</p>
				</div>
			</div>
		</div>

		<div class="block">
			<div class="check-wrap">
				<van-field v-model="formData.code" label="患者编号" label-width="60px" placeholder="输入患者编号以核查身份">
					<template #button>
						<button type="button" class="check-btn" @click="checkEvent">核查</button>
					</template>
				</van-field>
			</div>
		</div>

		<div class="block">
			<div class="info-wrap">
				<div class="info-row">
					<span class="label">患者：</span>
					<span class="value">小王</span>
				</div>
				<div class="info-row">
					<span class="label">床号：</span>
					<span class="value">34</span>
				</div>

				<div class="handle-wrap">
					<div class="left-pabel"><span class="tag">身份正确</span></div>
					<div class="right-panel">
						<button type="button" class="primary-btn">开始转运</button>
						<button type="button" class="primary-btn">导航</button>
					</div>
				</div>
			</div>
		</div>

		<div class="block">
			<div class="end-wrap">
				<p class="content">
					已到达目的地
					<span class="b">门诊楼4楼内科5号手术室</span>
					。请点击结束按钮完成此次订单。
				</p>
				<div class="handle-wrap"><button type="button">结束</button></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'workOrderDetail',
	components: {},
	data() {
		return {
			formData: {
				code: ''
			}
		};
	},
	created() {},
	mounted() {},
	methods: {
		checkEvent(){
			this.$toast.success('核查成功');
		}
	}
};
</script>

<style lang="scss" scoped>
.main-wrap {
	background: #f5f7f9;
	padding: 10px;
	.block {
		padding: 10px;
		background: #fff;
		border-radius: 8px;
		font-size: 14px;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	.title {
		display: flex;
		align-items: center;
		.label {
			color: #666;
		}
		.value {
			color: #3e73fb;
			font-weight: 700;
		}
	}

	.bar-list {
		display: flex;
		align-items: flex-start;
		.bar-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex: 1;
			&.active {
				.icon {
					background: #3e73fb;
				}
				.label,
				.time {
					color: #3e73fb;
				}
			}
			.icon {
				width: 20px;
				height: 20px;
				border-radius: 12px;
				background: #ccc;
			}
			.label {
				font-size: 14px;
				color: #999;
				margin: 8px 0;
			}
			.time {
				font-size: 12px;
				line-height: 12px;
				margin-top: 4px;
				color: #999;
			}
		}
	}

	.check-wrap {
		::v-deep.van-cell {
			padding: 0;
			.van-field__label {
				line-height: 32px;
			}
		}
		.check-btn {
			display: flex;
			font-size: 14px;
			color: #fff;
			background: #3e73fb;
			border-radius: 32px;
			padding: 0 10px;
			line-height: 32px;
		}
	}

	.info-wrap {
		.info-row {
			width: 50%;
			font-size: 14px;
			display: flex;
			align-items: center;
      $labelWidth: 50px;
			padding: 2px 0;
			.label {
				color: #666;
				width: #{$labelWidth};
			}
			.value {
				color: #333;
				width: calc(100% - #{$labelWidth});
			}
		}
		.handle-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 16px;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: -8px;
				border-top: 1px solid #ddd;
			}
			.left-pabel {
				.tag {
					padding: 1px 4px;
					border-radius: 6px;
					color: green;
					font-weight: 700;
					font-size: 14px;
					&.error {
						color: red;
					}
				}
			}
			button {
				padding: 0px 16px;
				line-height: 24px;
				border-radius: 24px;
				border: 1px solid #3e73fb;
				color: #3e73fb;
				font-size: 14px;
				background: none;
				&:not(:last-child) {
					margin-right: 10px;
				}
				&.danger-btn {
					border-color: red;
					color: red;
				}
			}
		}
	}

	.end-wrap {
		.content {
			color: #333;
			font-size: 14px;
			.b {
				font-weight: 700;
				color: #3e73fb;
			}
		}
		.handle-wrap {
			display: flex;
			justify-content: center;
			margin-top: 16px;
			button {
				width: 80%;
				line-height: 32px;
				border-radius: 24px;
				background: #3e73fb;
				color: #fff;
				font-size: 14px;
			}
		}
	}
}
</style>
